<template>
    <!-- <div class="outer" ref="scroll"> -->
    <!-- <div class="pulldown-wrapper">
            <div v-show="beforePullDown">
                <span>下拉刷新</span>
            </div>
            <div v-show="!beforePullDown">
                <div v-show="isPullingDown">
                    <span>加载中...</span>
                </div>
                <div v-show="!isPullingDown">
                    <span>刷新成功</span>
                </div>
            </div>
        </div> -->
    <div class="container">
        <div class="top-box">
            <img class="background" src="/img/main/background.png" alt="">
            <div class="top">{{ name }}</div>
            <div class="bottom">
                <div class="phone">{{ username }}</div>
            </div>
            <div></div>
        </div>
        <div class="create-box">
            <div class="create-box__title">
                <div><span style="color: #F7203E">*</span>机构名称</div>
            </div>
            <el-select ref="agentSelect" v-model="orgId" :popper-append-to-body="false" filterable
                placeholder="选择机构名称" @change="handleChange">
                <el-option v-for="item in inspectList" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
            </el-select>
            <div class="create-box__title">
                <div>评估类型: {{role == 3 ? '区县他评' : '市级他评'}}</div>
            </div>
            <div class="create-box__title">
                <div>情况说明</div>
            </div>
            <el-input
                type="textarea"
                placeholder="输入内容"
                v-model="explain"
                maxlength="200"
                show-word-limit
                >	
            </el-input>
        </div>
        <div class="btn-box" style="flex-direction: column">
            <el-button class="login-btn" @click="addCheck" :disabled="canAdd" type="primary" v-throttle>确定
            </el-button>
            <!-- <div class="footer">北京京学科技发展集团有限公司提供技术支持</div> -->
        </div>
        <!-- <div class="model-box" v-if="showModel">
            <div class="select-box">
                <div class="select-box__top">是否继续自评？</div>
                <div class="select-box__bottom">
                    <div class="select-box__bottom--left">重新自测</div>
                    <div class="select-box__bottom--mid"></div>
                    <div class="select-box__bottom--right">继续</div>
                </div>
            </div>
        </div>
        <div class="model" v-if="showModel"></div> -->
    </div>
    <!-- </div> -->
</template>
<script>

export default {
    name: "Create",
    data() {
        return {
            canAdd: true,
            role: '',
            name: '',
            username: '',
            inspectList: [],
            orgId: '',
            explain: ''
        };
    },
    watch: {
        orgId(newId, oldId) {
            if(newId != '') {
                this.canAdd = false
            }
        }
    },
    components: {

    },
    mounted() {
        this.name = localStorage.getItem('name')
        this.username = localStorage.getItem('username')
        this.role = localStorage.getItem('role')
        this.init()
    },
    methods: {
        async init() {
            const loading = this.$loading({
                lock: true,
                text: '加载中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            let res = await this.$api({
                method: "get",
                url: "/api/organ/list",
                data: {
                    page: 1,
                    limit: 1000,
                }
            });
            console.log(1)
            loading.close();
            if (res.code == 0) {
                console.log(res.data)
                this.inspectList = res.data.list
            } else {
                this.$message({
                    message: res.msg,
                    type: "warning",
                });
            }
        },
        async addCheck() {
            let that = this
            let res = await this.$api({
                method: 'get',
                url: '/api/inspect/create',
                data: {
                    organization_id: that.orgId,
                    explain: that.explain
                }
            })
            this.$router.replace({ path: '/list', query: { id: res.data.inspect_id, type: 2, is_new: 1 }})
            this.canCheck = false
        },
        handleChange(id) {
            this.orgId = id
        },
    },
};
</script>
<style lang="scss">
.el-select-dropdown__item {
    width: 100%;
}
.container {
    height: 100%;

    .top-box {
        position: fixed;
        z-index: 2;
        top: 0;
        width: 100%;
        height: 125px;
        // background-image: url('/img/main/background.png');
        // background-size: 100% 125px;

        .background {
            position: absolute;
            left: 0;
            z-index: -1;
            width: 100%;
            height: 125px;
        }

        .top {
            margin: 25px 0 0 15px;
            text-align: left;
            font-size: 18px;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 27px;
        }

        .bottom {
            z-index: 99;
            margin: 4px 12px 0 15px;
            display: flex;
            align-items: center;

            .phone {
                margin-right: auto;
                font-size: 14px;
                font-weight: 400;
                color: #FFF;
                line-height: 20px;
            }

            .guide {
                font-size: 13px;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 20px;
                background-clip: text;
            }

            .icon {
                margin-left: 3px;
                width: 13px;
                height: 13px;
            }
        }
    }

    .create-box {
        position: absolute;
        top: 100px;
        z-index: 99;
        height: calc(100% - 100px);
        width: 100%;
        overflow-y: scroll;
        background: #F7F8FA;
        border-radius: 10px 10px 0px 0px;
        padding: 0 15px;

        .create-box__title {
            margin-top: 15px;
            display: flex;
            text-align: left;
            align-items: center;
            font-size: 15px;
            font-weight: 500;
            color: #333333;
            line-height: 24px;
        }

        .el-select {
            margin-top: 15px;
            width: 100%;

            .el-input__inner {
                width: 100%;
                background: #fff;
            }
        }

        .el-textarea {
            margin-top: 15px;
            
            textarea::placeholder {
                font-size: 17px;
                font-weight: 400;
                color: #B0B3BC;
                line-height: 26px;
            }
            .el-textarea__inner {
                border: none;
                min-height: 114px !important;
            }
        }
    }

    .btn-box {
        z-index: 100;
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 73px;
        background: #FFFFFF;
        box-shadow: 0px -1px 4px 0px rgba(242, 242, 242, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;

        .login-btn {
            width: 323px;
            font-size: 19px;
            color: #ffffff;
        }
    }

}
</style>